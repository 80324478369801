import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { CustomParaGraph, PageLoader } from "../../components";
import SubHeading from "../../components/SubHeading/SubHeading";

import SideBanner from "../../assets/images/contact/contactSide.png";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import MailIcon from "@mui/icons-material/Mail";
import { Footer, Header, PageHeading } from "../../components";
import { Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import WarningMessage from "../../components/WarningMessage/WarningMessage";
import { Message } from "../../Constant/StaticData";
import bannerImg from "../../assets/images/contact/contact_banner.jpg";
import ApiRequest from "../../utility/APIRequest";
import checkImg from "../../assets/images/contact/check.png";

const ContactUS = () => {
  const [formLog, setFormLog] = useState({
    loading: false,
    emailsent: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormLog({ loading: true, emailsent: false });
    const ApiData = data;
    ApiRequest.request("/contact", "POST", ApiData).then((res) => {
      // res?.status &&
      setFormLog({ loading: false, emailsent: true });
    });
  };

  return (
    <div className="contactus">
      <PageLoader open={formLog?.loading} message="We are processing the data. Please wait..." />
      <Header />
      <img src={bannerImg} className="contactus__banner" alt="Contact us banner" />
      <Container maxWidth="xl" id="contact">
        <Card className="contactus__info">
          <Paper elevation={3} style={{ backgroundColor: "aliceblue", padding: "25px 10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Email"
                    secondary={
                      <>
                        <b>Sales:</b> sales@zcelindia.com <br /> <b>Support:</b>
                        info@zcelindia.com
                      </>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item xs={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ContactPhoneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Phone Number"
                    secondary={
                      <>
                        <b>Sales:</b> +91-9821342553 <br /> <b>Support:</b>
                        +91-8604507250
                      </>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item xs={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CorporateFareIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Address" secondary="F15, Kheri Kalan, Greater Faridabad, Faridabad, Haryana 121002" />
                </ListItem>
              </Grid>
            </Grid>
          </Paper>
        </Card>
      </Container>
      <div className="contactus__formSection">
        <Container maxWidth="lg">
          <div className="contactus__formSection__content">
            <Grid container spacing={2}>
              {!formLog?.emailsent ? (
                <>
                  <Grid item xs={5} className="contactus__formSection__content__imagesection">
                    <img src={SideBanner} alt="company info" className="contactus__formSection__content__imagesection__img" />
                  </Grid>
                  <Grid item xs={7} className="contactus__formSection__content__form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <SubHeading text="Request for a demo" />
                      <CustomParaGraph text="Kindly fill the information below we will contact you shortly." />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                            <InputLabel htmlFor="company_name">Company Name</InputLabel>
                            <Input
                              id="company_name"
                              type="text"
                              {...register("company_name", {
                                min: 2,
                                required: true,
                              })}
                            />
                          </FormControl>
                          {errors?.company_name?.type === "required" && <WarningMessage type="error" text={Message.COMPANY_NAME_REQUIRED} />}
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                            <InputLabel htmlFor="Name">Person Name</InputLabel>
                            <Input id="person_name" type="text" {...register("person_name", { required: true })} />
                          </FormControl>
                          {errors?.person_name?.type === "required" && <WarningMessage type="error" text="Name is required" />}
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                            <InputLabel htmlFor="contact_email">Email Address</InputLabel>
                            <Input
                              id="contact_email"
                              type="text"
                              {...register("contact_email", {
                                min: 2,
                                required: true,
                                pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              })}
                            />
                          </FormControl>
                          {errors?.contact_email && <WarningMessage type="error" text="Please provide the currect email" />}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                            <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
                            <Input
                              id="phone_number"
                              type="text"
                              {...register("phone_number", {
                                min: 2,
                                required: true,
                                pattern: /^(0|[+91]{3})?[7-9][0-9]{9}$/,
                              })}
                            />
                          </FormControl>
                          {errors?.phone_number && <WarningMessage type="error" text="Please provide the currect phone number" />}
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                            <InputLabel htmlFor="message">Your Message</InputLabel>
                            <Input id="message" type="text" {...register("message", { required: true })} />
                          </FormControl>
                          {errors?.message && <WarningMessage type="error" text="Please help me with your query" />}
                        </Grid>
                        <Grid item xs={12} className="contactus__content__formSection__submit">
                          <Button variant="contained" type="submit" endIcon={<SendIcon />}>
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </>
              ) : (
                <div className="contactus__formSection__content__thankyou">
                  <img src={checkImg} alt="Form submitted" />
                  <SubHeading text="Thank You!" customStyle={{ textAlign: "center", color: "#22bd53" }} />
                  <CustomParaGraph customStyle={{ textAlign: "center" }} text="Your requested is submitted! Our team will connect you shortly." />
                </div>
              )}
            </Grid>
          </div>
        </Container>

        <Container maxWidth="xl">
          <div className="contactus__disclamer">
            <Typography variant="body2" color="text.secondary">
              <b>Disclamer:</b> By submitting a form via this website, you consent that, for the purpose of contacting you, responding to your query, directing you to the appropriate person within Credit Suisse and providing you with publications or
              materials relating to our events and seminars, digital capabilities, financial, banking, securities, investment products and services, including derivatives, securities trading, commodity and equity sales, exchanged-traded funds and
              mortgages, provided by us or third party financial institutions, insurers, securities and investment service providers, by such communication means as we deem appropriate (including by email, voice call or text message to any phone
              number that you provide to us), your name, phone number, country of residence and email address (your “Data”) may be shared, in electronic and/or physical form, with employees of Credit Suisse AG in Switzerland, Credit Suisse AG in
              Singapore (collectively, “Credit Suisse”). If you do not provide us with Data marked as mandatory in this form, or consent to the use/disclosure of your Data as outlined above, we may be unable to contact you, respond to your query,
              direct you to the appropriate person within Credit Suisse or provide you with the materials or information outlined above.
            </Typography>
          </div>
        </Container>
        <container maxWidth="xl">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.4954186905843!2d77.37149827560202!3d28.404304394407898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd060c25595b%3A0xab340652333cb1b!2sZcel%20India%20LLP!5e0!3m2!1sen!2sin!4v1706096527107!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </container>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUS;
