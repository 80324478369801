import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";

const DownloadExcels = (data) => {
  const CheckPopup = (header, text) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: header ? header : "Your work has been saved",
      text: text
        ? text
        : "You won't be able to revert this! You won't be able to revert this! You won't be able to revert this! You won't be able to revert this! You won't be able to revert this! You won't be able to revert this!",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const handleDownloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
    CheckPopup(
      "Thankyou for downloading!",
      "Your Data is downloaded sucessfully."
    );
  };

  return (
    <Button
      startIcon={<CloudDownloadIcon />}
      onClick={() => handleDownloadExcel(data?.result?.records)}
    >
      Download
    </Button>
  );
};
export default DownloadExcels;
