import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Login from "../Login";
import Status from "../Status";
import Signup from "../Signup/Signup";
import Forgot from "../Forgot/Forgot";
import logoLight from "../../assets/images/logo-light.png";
import profileImg from "../../assets/images/profile.png";
import CustomizedDialogs from "../popup/Popup";
import { handleLogout } from "../../Utils";
import { useNavigate } from "react-router-dom";
import PromotionalPopup from "../PromotionalPopup";
import ApiRequest from "../../utility/APIRequest";
import API_endpoint from "../../utility/APIEndPoints";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

export default function Header(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [isLogin, setIsLogin] = useState(false);
  const [ispopup, setIsPopup] = useState(false);
  const [ispopuptype, setIispopuptype] = useState("login");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseAuthanticationModal = async () => {
    await setIispopuptype("login");
    setIsPopup(false);
  };
  const getVerifyToken = () => {
    ApiRequest.request(API_endpoint?.getProfile, "GET").then((res) => {
      if (res?.result?.customer) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
        navigate("/");
      }
    });
  };

  const handleAfterUserLogin = (type) => {
    if (type === "login") {
      if (location?.pathname === "/trade-analysis" || location?.pathname === "/USA-trade-analysis" || location?.pathname === "/buyer" || location?.pathname === "/supplier") {
        getVerifyToken();
      } else {
        setIsLogin(true);
        setIsPopup(false);
      }
    } else if (type === "logout") {
      handleLogout();
    } else {
      setIsLogin(false);
      if (location?.pathname === "/trade-analysis" || location?.pathname === "/USA-trade-analysis" || location?.pathname === "/buyer" || location?.pathname === "/supplier") {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      handleAfterUserLogin("login");
    } else {
      handleAfterUserLogin();
    }
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Bizdiving
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const manageTradeAnalysisButton = (path) => {
    if (isLogin) {
      navigate(path);
    } else {
      setIsPopup(true);
    }
  };

  return (
    <div className="header">
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" color="default">
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }} className="header__logoDiv">
              <Link to="/" className="header__logoDiv__logo">
                <img src={logoLight} alt="Logo" className="header__logoDiv__logo__img" />
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button key="about" sx={{ color: "#fff" }}>
                <Link to={"/about"} className="header__link">
                  About US
                </Link>
              </Button>
              <Button key="importexport" sx={{ color: "#fff" }} onClick={() => manageTradeAnalysisButton("/trade-analysis")}>
                <Link className="header__link">TRADE ANALYSIS</Link>
              </Button>
              <Button key="importexport" sx={{ color: "#fff" }} onClick={() => manageTradeAnalysisButton("/usa-trade-analysis?country=usa")}>
                <Link className="header__link">USA TRADE ANALYSIS</Link>
              </Button>
              <Button key="buyer" sx={{ color: "#fff" }} onClick={() => manageTradeAnalysisButton("/buyer")}>
                <Link className="header__link">Buyer</Link>
              </Button>
              <Button key="supplier" sx={{ color: "#fff" }} onClick={() => manageTradeAnalysisButton("/supplier")}>
                <Link className="header__link">Supplier</Link>
              </Button>
              <Button key="contactus" sx={{ color: "#fff" }}>
                <Link to={"/contact-us"} className="header__link">
                  Contact
                </Link>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {isLogin ? (
                <>
                  <Tooltip title="Open profile">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt="Remy Sharp" src={profileImg} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Link to={"/profile"} className="header__MenuLink">
                        <Typography textAlign="center">Profile</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={() => handleAfterUserLogin("logout")}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Tooltip title="Open profile">
                  <Button
                    key="contactus"
                    variant="contained"
                    sx={{ color: "#fff", fontWeight: 600, fontSize: "12px" }}
                    onClick={() => {
                      setIsPopup(true);
                    }}
                  >
                    Login
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <CustomizedDialogs
        isOpen={ispopup}
        onClose={() => {
          setIsPopup(false);
        }}
        title={ispopuptype === "login" ? "Login" : ispopuptype === "signup" ? "Sign Up" : "Forgot Password"}
      >
        {ispopuptype === "login" ? (
          <Login isSignup={() => setIispopuptype("signup")} isForgot={() => setIispopuptype("forgot")} closeModal={() => handleCloseAuthanticationModal()} isUserLogedin={() => handleAfterUserLogin("login")} />
        ) : ispopuptype === "signup" ? (
          <Signup externalLink={() => setIispopuptype("login")} closeModal={() => handleCloseAuthanticationModal()} />
        ) : ispopuptype === "forgot" ? (
          <Forgot isLogin={() => setIispopuptype("login")} closeModal={() => handleCloseAuthanticationModal()} />
        ) : (
          <Status />
        )}
      </CustomizedDialogs>
      {/* <PromotionalPopup /> */}
    </div>
  );
}
