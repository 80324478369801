import React, { useEffect, useRef, useState } from "react";

import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";

import { PageLoader } from "../../components";
import { Footer, CustomizedTables, Header } from "../../components";
import { GetPagination, handleSerchDataFormat } from "../../Utils/commonFunction";
import { CustomAlert } from "../../Utils";
import ApiRequest from "../../utility/APIRequest";
import Promotions from "../../components/Promotions/Promotions";
import DashboardReportBox from "../../components/DashboardReportBox/DashboardReportBox";
import { useSearchParams } from "react-router-dom";
import { displayListForIndianData } from "../../Constant/StaticData";

const FullDataTable = () => {
  const [searchParams] = useSearchParams();
  const isFirstTime = useRef(true);
  const isLoading = useRef(false);
  const [formData, setFormData] = useState();
  const [page, setPage] = useState("1");
  const [filteredData, setFilteredData] = useState();
  const [shortAnalysis, setShortAnalysis] = useState({ loader: false, data: [] });

  let query = Object.fromEntries(searchParams.entries());

  const getFilterData = async (data, isShortAnalysisRequire = true) => {
    if (data && !isLoading?.current) {
      isLoading.current = true;
      let APIData = await handleSerchDataFormat(data, page, false);
      let ApiEndPoint = query?.country === "usa" ? `/analytics/usa/${data?.dataType}/search` : `/analytics/${data?.dataType}/search`;

      ApiRequest.request(ApiEndPoint, "POST", APIData).then((response) => {
        if (response?.status) {
          setFilteredData(response?.result);
          isLoading.current = false;
          if (response?.result?.subscription && page === "1" && isShortAnalysisRequire) {
            isFirstTime.current = false;
            getShortAnalysis(APIData, data?.dataType);
          }
        } else {
          isLoading.current = false;
          CustomAlert("Invalid Action", "something went wrong!", "error", 5000);
        }
      });
    }
  };

  // Handling short analysis
  const getShortAnalysis = (apiData, type) => {
    setShortAnalysis({ loader: true, data: [] });
    let apiEndPoint = query?.country === "usa" ? `/analytics/usa/${type}/sort-analysis` : `/analytics/${type}/sort-analysis`;

    ApiRequest.request(apiEndPoint, "POST", apiData).then((response) => {
      if (response?.status) {
        setShortAnalysis({ loader: false, data: response?.result });
      }
    });
  };

  const handleDataUpdate = () => {
    if (isFirstTime?.current) {
      let filterCountry = query?.country === "usa" ? "usa_filter" : "india_filter";
      let localStorageData = localStorage.getItem(filterCountry);
      let data = JSON.parse(localStorageData); // filter obj got from local storage

      if (query?.isFind) {
        data[query?.keyVal] = [query?.val];
      }
      setFormData(data);
      console.log("data", data);
      getFilterData(data, true);
    } else if (page !== "1") {
      getFilterData(formData, true);
    }
  };

  const handleDataOrder = (orderFor, value) => {
    let data = formData;
    data["sort"] = {};
    data["sort"]["sort_field"] = orderFor;
    data["sort"]["sort_order"] = value;
    getFilterData(data, false);
  };

  useEffect(() => {
    handleDataUpdate();
  }, [page]);

  return (
    <div className="import_export">
      <PageLoader open={isLoading?.current} />
      <Header />

      <Container maxWidth="xl" className="import_export_filter">
        {isFirstTime?.current ? (
          <>
            <div style={{ height: "70vh" }}></div>
          </>
        ) : filteredData?.subscription ? (
          <>
            <div className="import_export_filter__tableCard__data">
              <DashboardReportBox data={shortAnalysis?.data} displayList={displayListForIndianData} noOfBoard={5} />

              <br />

              <CustomizedTables tableData={filteredData?.searchResult} isSubscription={filteredData?.subscription} filterType={formData?.dataType} triggerOrder={(orderFor, value) => handleDataOrder(orderFor, value)} />
              <div className="import_export_filter__tableCard__data__pagination">
                {shortAnalysis?.data?.Shipments && <Pagination count={GetPagination(shortAnalysis?.data?.Shipments)} onChange={(event, value) => setPage(value)} showFirstButton showLastButton color="primary" />}
              </div>
            </div>
          </>
        ) : (
          <Promotions heading="You don't have subscription!" message="You don't have subscription for see full data. Please connect support to get the subscription or request for demo." />
        )}
      </Container>

      <Footer />
    </div>
  );
};
export default FullDataTable;
