import React from "react";
import Carousel from "react-bootstrap/Carousel";
import sliderImage1 from "../../assets/images/Slider/1.png";
import sliderImage2 from "../../assets/images/Slider/2.png";
import sliderImage3 from "../../assets/images/Slider/3.png";

import "bootstrap/dist/css/bootstrap.min.css";

function ControlledCarousel() {
  return (
    <Carousel data-bs-theme="dark" interval="2000" indicators={false}>
      {/* <Carousel.Item>
        <img className="d-block w-100" src={sliderImage1} alt="Second slide" />
        <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item> */}
      <Carousel.Item>
        <img className="d-block w-100" src={sliderImage2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={sliderImage3} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
